"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationType = exports.UserType = exports.UserGender = exports.TrialStatus = exports.TrackingStatus = exports.SurveyStatus = exports.SurveyQuestionType = exports.SurveyFrequency = exports.SubjectStatus = exports.SortByOrder = exports.SiteStatus = exports.SiteStaffRole = exports.ShipmentLabelStatus = exports.ScheduledItemType = exports.RegistrationStatus = exports.RecipeType = exports.PlatformType = exports.PhysicalActivityLevel = exports.NotificationTypeV2 = exports.NotificationType = exports.MenuType = exports.MealType = exports.MainGoalType = exports.LogicCondition = exports.LogicAction = exports.FormBuilderQuestionType = exports.FoodProgramStatus = exports.FilterOp = exports.FeatureFlagType = exports.DocumentOwnerFilter = exports.DocumentOverwriteOption = exports.DishType = exports.DeviceNotificationChannel = exports.DeliveryStatus = exports.DeliverySettings = exports.DeliveryKey = exports.DateQuestionInitialState = exports.CustomFieldName = exports.CourseAssignmentTypes = exports.ContentExplanationType = exports.CoachPrefType = exports.AssignType = exports.AppointmentLocation = exports.AppointmentDuration = exports.AddressValidationStatus = exports.ActivityReminder = exports.ActivityKind = exports.ActivityFrequency = exports.ActivitiesPaginationMode = exports.AccessType = void 0;
exports.GetFeatureFlagsDocument = exports.GetDietPlanDocument = exports.GetMenuDocument = exports.ListDeliveriesDocument = exports.ListDietPlansDocument = exports.GetPatientUserDocument = exports.GetCtPatientDocument = exports.ListCtPatientsDocument = exports.ListMenusDocument = exports.ListDishesDocument = exports.MeDocument = exports.ReactivateDeliveryDocument = exports.CancelDeliveryDocument = exports.UpdateDietPlanDocument = exports.DuplicateDietPlanDocument = exports.AddDietPlanDocument = exports.UpdateProfileDocument = exports.UpdateMenuDocument = exports.DuplicateMenuDocument = exports.AddMenuDocument = exports.WeekDay = void 0;
exports.useAddMenuMutation = useAddMenuMutation;
exports.useDuplicateMenuMutation = useDuplicateMenuMutation;
exports.useUpdateMenuMutation = useUpdateMenuMutation;
exports.useUpdateProfileMutation = useUpdateProfileMutation;
exports.useAddDietPlanMutation = useAddDietPlanMutation;
exports.useDuplicateDietPlanMutation = useDuplicateDietPlanMutation;
exports.useUpdateDietPlanMutation = useUpdateDietPlanMutation;
exports.useCancelDeliveryMutation = useCancelDeliveryMutation;
exports.useReactivateDeliveryMutation = useReactivateDeliveryMutation;
exports.useMeQuery = useMeQuery;
exports.useMeLazyQuery = useMeLazyQuery;
exports.useListDishesQuery = useListDishesQuery;
exports.useListDishesLazyQuery = useListDishesLazyQuery;
exports.useListMenusQuery = useListMenusQuery;
exports.useListMenusLazyQuery = useListMenusLazyQuery;
exports.useListCtPatientsQuery = useListCtPatientsQuery;
exports.useListCtPatientsLazyQuery = useListCtPatientsLazyQuery;
exports.useGetCtPatientQuery = useGetCtPatientQuery;
exports.useGetCtPatientLazyQuery = useGetCtPatientLazyQuery;
exports.useGetPatientUserQuery = useGetPatientUserQuery;
exports.useGetPatientUserLazyQuery = useGetPatientUserLazyQuery;
exports.useListDietPlansQuery = useListDietPlansQuery;
exports.useListDietPlansLazyQuery = useListDietPlansLazyQuery;
exports.useListDeliveriesQuery = useListDeliveriesQuery;
exports.useListDeliveriesLazyQuery = useListDeliveriesLazyQuery;
exports.useGetMenuQuery = useGetMenuQuery;
exports.useGetMenuLazyQuery = useGetMenuLazyQuery;
exports.useGetDietPlanQuery = useGetDietPlanQuery;
exports.useGetDietPlanLazyQuery = useGetDietPlanLazyQuery;
exports.useGetFeatureFlagsQuery = useGetFeatureFlagsQuery;
exports.useGetFeatureFlagsLazyQuery = useGetFeatureFlagsLazyQuery;
var client_1 = require("@apollo/client");
var Apollo = __importStar(require("@apollo/client"));
var defaultOptions = {};
var AccessType;
(function (AccessType) {
    AccessType["Download"] = "download";
    AccessType["Upload"] = "upload";
})(AccessType || (exports.AccessType = AccessType = {}));
var ActivitiesPaginationMode;
(function (ActivitiesPaginationMode) {
    ActivitiesPaginationMode["Backward"] = "Backward";
    ActivitiesPaginationMode["Bidirectional"] = "Bidirectional";
    ActivitiesPaginationMode["Forward"] = "Forward";
})(ActivitiesPaginationMode || (exports.ActivitiesPaginationMode = ActivitiesPaginationMode = {}));
var ActivityFrequency;
(function (ActivityFrequency) {
    ActivityFrequency["Biweekly"] = "Biweekly";
    ActivityFrequency["Daily"] = "Daily";
    ActivityFrequency["Monthly"] = "Monthly";
    ActivityFrequency["Once"] = "Once";
    ActivityFrequency["Triweekly"] = "Triweekly";
    ActivityFrequency["Weekly"] = "Weekly";
})(ActivityFrequency || (exports.ActivityFrequency = ActivityFrequency = {}));
var ActivityKind;
(function (ActivityKind) {
    ActivityKind["Appointment"] = "Appointment";
    ActivityKind["Goal"] = "Goal";
    ActivityKind["Task"] = "Task";
})(ActivityKind || (exports.ActivityKind = ActivityKind = {}));
var ActivityReminder;
(function (ActivityReminder) {
    ActivityReminder["FifteenMinutes"] = "FifteenMinutes";
    ActivityReminder["Never"] = "Never";
    ActivityReminder["OneDay"] = "OneDay";
    ActivityReminder["TenMinutes"] = "TenMinutes";
    ActivityReminder["ThirtyMinutes"] = "ThirtyMinutes";
})(ActivityReminder || (exports.ActivityReminder = ActivityReminder = {}));
var AddressValidationStatus;
(function (AddressValidationStatus) {
    AddressValidationStatus["Invalid"] = "Invalid";
    AddressValidationStatus["MissingComplement"] = "MissingComplement";
    AddressValidationStatus["Ok"] = "Ok";
})(AddressValidationStatus || (exports.AddressValidationStatus = AddressValidationStatus = {}));
var AppointmentDuration;
(function (AppointmentDuration) {
    AppointmentDuration["FifteenMinutes"] = "FifteenMinutes";
    AppointmentDuration["OneHour"] = "OneHour";
    AppointmentDuration["ThirtyMinutes"] = "ThirtyMinutes";
})(AppointmentDuration || (exports.AppointmentDuration = AppointmentDuration = {}));
var AppointmentLocation;
(function (AppointmentLocation) {
    AppointmentLocation["InPerson"] = "InPerson";
    AppointmentLocation["Virtual"] = "Virtual";
})(AppointmentLocation || (exports.AppointmentLocation = AppointmentLocation = {}));
var AssignType;
(function (AssignType) {
    AssignType["DhAndCaretaker"] = "dh_and_caretaker";
    AssignType["Manual"] = "manual";
})(AssignType || (exports.AssignType = AssignType = {}));
var CoachPrefType;
(function (CoachPrefType) {
    CoachPrefType["Female"] = "female";
    CoachPrefType["Male"] = "male";
    CoachPrefType["NoPref"] = "no_pref";
})(CoachPrefType || (exports.CoachPrefType = CoachPrefType = {}));
var ContentExplanationType;
(function (ContentExplanationType) {
    ContentExplanationType["Image"] = "image";
    ContentExplanationType["Text"] = "text";
})(ContentExplanationType || (exports.ContentExplanationType = ContentExplanationType = {}));
var CourseAssignmentTypes;
(function (CourseAssignmentTypes) {
    CourseAssignmentTypes["None"] = "none";
    CourseAssignmentTypes["Site"] = "site";
    CourseAssignmentTypes["Trial"] = "trial";
})(CourseAssignmentTypes || (exports.CourseAssignmentTypes = CourseAssignmentTypes = {}));
var CustomFieldName;
(function (CustomFieldName) {
    CustomFieldName["Sachets"] = "sachets";
    CustomFieldName["TreatmentStartDate"] = "treatmentStartDate";
})(CustomFieldName || (exports.CustomFieldName = CustomFieldName = {}));
var DateQuestionInitialState;
(function (DateQuestionInitialState) {
    DateQuestionInitialState["NoPreloadedDate"] = "noPreloadedDate";
    DateQuestionInitialState["PreloadedWithCurrentDate"] = "preloadedWithCurrentDate";
})(DateQuestionInitialState || (exports.DateQuestionInitialState = DateQuestionInitialState = {}));
var DeliveryKey;
(function (DeliveryKey) {
    DeliveryKey["WeekAFirst"] = "weekAFirst";
    DeliveryKey["WeekASecond"] = "weekASecond";
    DeliveryKey["WeekBFirst"] = "weekBFirst";
    DeliveryKey["WeekBSecond"] = "weekBSecond";
    DeliveryKey["WeekCFirst"] = "weekCFirst";
    DeliveryKey["WeekCSecond"] = "weekCSecond";
})(DeliveryKey || (exports.DeliveryKey = DeliveryKey = {}));
var DeliverySettings;
(function (DeliverySettings) {
    DeliverySettings["Fewest"] = "fewest";
    DeliverySettings["Freshest"] = "freshest";
})(DeliverySettings || (exports.DeliverySettings = DeliverySettings = {}));
var DeliveryStatus;
(function (DeliveryStatus) {
    DeliveryStatus["Closed"] = "closed";
    DeliveryStatus["Open"] = "open";
})(DeliveryStatus || (exports.DeliveryStatus = DeliveryStatus = {}));
var DeviceNotificationChannel;
(function (DeviceNotificationChannel) {
    DeviceNotificationChannel["Apns"] = "APNS";
    DeviceNotificationChannel["Gcm"] = "GCM";
})(DeviceNotificationChannel || (exports.DeviceNotificationChannel = DeviceNotificationChannel = {}));
var DishType;
(function (DishType) {
    DishType["Meal"] = "meal";
    DishType["Snack"] = "snack";
})(DishType || (exports.DishType = DishType = {}));
var DocumentOverwriteOption;
(function (DocumentOverwriteOption) {
    DocumentOverwriteOption["Overwrite"] = "overwrite";
    DocumentOverwriteOption["Preserve"] = "preserve";
})(DocumentOverwriteOption || (exports.DocumentOverwriteOption = DocumentOverwriteOption = {}));
var DocumentOwnerFilter;
(function (DocumentOwnerFilter) {
    DocumentOwnerFilter["Both"] = "both";
    DocumentOwnerFilter["Coach"] = "coach";
    DocumentOwnerFilter["Patient"] = "patient";
})(DocumentOwnerFilter || (exports.DocumentOwnerFilter = DocumentOwnerFilter = {}));
var FeatureFlagType;
(function (FeatureFlagType) {
    FeatureFlagType["Mobile"] = "Mobile";
    FeatureFlagType["Web"] = "Web";
})(FeatureFlagType || (exports.FeatureFlagType = FeatureFlagType = {}));
var FilterOp;
(function (FilterOp) {
    FilterOp["Equal"] = "equal";
    FilterOp["Includes"] = "includes";
})(FilterOp || (exports.FilterOp = FilterOp = {}));
var FoodProgramStatus;
(function (FoodProgramStatus) {
    FoodProgramStatus["Active"] = "active";
    FoodProgramStatus["Archived"] = "archived";
})(FoodProgramStatus || (exports.FoodProgramStatus = FoodProgramStatus = {}));
var FormBuilderQuestionType;
(function (FormBuilderQuestionType) {
    FormBuilderQuestionType["Binary"] = "Binary";
    FormBuilderQuestionType["Date"] = "Date";
    FormBuilderQuestionType["MultipleChoice"] = "MultipleChoice";
    FormBuilderQuestionType["Number"] = "Number";
    FormBuilderQuestionType["OpenText"] = "OpenText";
    FormBuilderQuestionType["Scale"] = "Scale";
    FormBuilderQuestionType["SingleChoice"] = "SingleChoice";
})(FormBuilderQuestionType || (exports.FormBuilderQuestionType = FormBuilderQuestionType = {}));
var LogicAction;
(function (LogicAction) {
    LogicAction["Hide"] = "Hide";
    LogicAction["Show"] = "Show";
})(LogicAction || (exports.LogicAction = LogicAction = {}));
var LogicCondition;
(function (LogicCondition) {
    LogicCondition["Contains"] = "Contains";
    LogicCondition["EqualTo"] = "EqualTo";
    LogicCondition["NotContains"] = "NotContains";
    LogicCondition["NotEqualTo"] = "NotEqualTo";
})(LogicCondition || (exports.LogicCondition = LogicCondition = {}));
var MainGoalType;
(function (MainGoalType) {
    MainGoalType["Connect"] = "connect";
    MainGoalType["Learn"] = "learn";
    MainGoalType["Support"] = "support";
    MainGoalType["Track"] = "track";
})(MainGoalType || (exports.MainGoalType = MainGoalType = {}));
var MealType;
(function (MealType) {
    MealType["Breakfast"] = "breakfast";
    MealType["Dinner"] = "dinner";
    MealType["Lunch"] = "lunch";
    MealType["Sides"] = "sides";
    MealType["Snacks"] = "snacks";
})(MealType || (exports.MealType = MealType = {}));
var MenuType;
(function (MenuType) {
    MenuType["QuickShip"] = "quick_ship";
    MenuType["Regular"] = "regular";
})(MenuType || (exports.MenuType = MenuType = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["None"] = "none";
    NotificationType["Push"] = "push";
    NotificationType["Sms"] = "sms";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
var NotificationTypeV2;
(function (NotificationTypeV2) {
    NotificationTypeV2["Push"] = "push";
    NotificationTypeV2["Sms"] = "sms";
})(NotificationTypeV2 || (exports.NotificationTypeV2 = NotificationTypeV2 = {}));
var PhysicalActivityLevel;
(function (PhysicalActivityLevel) {
    PhysicalActivityLevel["Moderately"] = "moderately";
    PhysicalActivityLevel["Sedentary"] = "sedentary";
    PhysicalActivityLevel["SlightlyActive"] = "slightly_active";
    PhysicalActivityLevel["VeryActive"] = "very_active";
})(PhysicalActivityLevel || (exports.PhysicalActivityLevel = PhysicalActivityLevel = {}));
var PlatformType;
(function (PlatformType) {
    PlatformType["Android"] = "Android";
    PlatformType["Ios"] = "Ios";
    PlatformType["Web"] = "Web";
})(PlatformType || (exports.PlatformType = PlatformType = {}));
var RecipeType;
(function (RecipeType) {
    RecipeType["Breakfast"] = "Breakfast";
    RecipeType["Dinner"] = "Dinner";
    RecipeType["Lunch"] = "Lunch";
    RecipeType["Snack"] = "Snack";
})(RecipeType || (exports.RecipeType = RecipeType = {}));
var RegistrationStatus;
(function (RegistrationStatus) {
    RegistrationStatus["Confirmed"] = "CONFIRMED";
    RegistrationStatus["ForceChangePassword"] = "FORCE_CHANGE_PASSWORD";
})(RegistrationStatus || (exports.RegistrationStatus = RegistrationStatus = {}));
var ScheduledItemType;
(function (ScheduledItemType) {
    ScheduledItemType["Appointment"] = "appointment";
    ScheduledItemType["Article"] = "article";
    ScheduledItemType["Bhb"] = "bhb";
    ScheduledItemType["Goal"] = "goal";
    ScheduledItemType["Learning"] = "learning";
    ScheduledItemType["Medication"] = "medication";
    ScheduledItemType["Survey"] = "survey";
    ScheduledItemType["Task"] = "task";
})(ScheduledItemType || (exports.ScheduledItemType = ScheduledItemType = {}));
var ShipmentLabelStatus;
(function (ShipmentLabelStatus) {
    ShipmentLabelStatus["Error"] = "ERROR";
    ShipmentLabelStatus["Queued"] = "QUEUED";
    ShipmentLabelStatus["Refunded"] = "REFUNDED";
    ShipmentLabelStatus["Refundpending"] = "REFUNDPENDING";
    ShipmentLabelStatus["Refundrejected"] = "REFUNDREJECTED";
    ShipmentLabelStatus["Success"] = "SUCCESS";
    ShipmentLabelStatus["Waiting"] = "WAITING";
})(ShipmentLabelStatus || (exports.ShipmentLabelStatus = ShipmentLabelStatus = {}));
var SiteStaffRole;
(function (SiteStaffRole) {
    SiteStaffRole["Crc"] = "crc";
    SiteStaffRole["Nurse"] = "nurse";
    SiteStaffRole["Pi"] = "pi";
    SiteStaffRole["SubI"] = "sub_i";
})(SiteStaffRole || (exports.SiteStaffRole = SiteStaffRole = {}));
var SiteStatus;
(function (SiteStatus) {
    SiteStatus["Active"] = "active";
    SiteStatus["Inactive"] = "inactive";
})(SiteStatus || (exports.SiteStatus = SiteStatus = {}));
var SortByOrder;
(function (SortByOrder) {
    SortByOrder["Asc"] = "asc";
    SortByOrder["Desc"] = "desc";
})(SortByOrder || (exports.SortByOrder = SortByOrder = {}));
var SubjectStatus;
(function (SubjectStatus) {
    SubjectStatus["CoachApproved"] = "coach_approved";
    SubjectStatus["Discontinued"] = "discontinued";
    SubjectStatus["Draft"] = "draft";
    SubjectStatus["Ongoing"] = "ongoing";
    SubjectStatus["ScreenFailed"] = "screen_failed";
    SubjectStatus["Screening"] = "screening";
    SubjectStatus["StudyCompleted"] = "study_completed";
})(SubjectStatus || (exports.SubjectStatus = SubjectStatus = {}));
var SurveyFrequency;
(function (SurveyFrequency) {
    SurveyFrequency["Biweekly"] = "Biweekly";
    SurveyFrequency["Daily"] = "Daily";
    SurveyFrequency["DaysOnDiet"] = "DaysOnDiet";
    SurveyFrequency["Monthly"] = "Monthly";
    SurveyFrequency["Once"] = "Once";
    SurveyFrequency["Triweekly"] = "Triweekly";
    SurveyFrequency["Weekly"] = "Weekly";
})(SurveyFrequency || (exports.SurveyFrequency = SurveyFrequency = {}));
var SurveyQuestionType;
(function (SurveyQuestionType) {
    SurveyQuestionType["Binary"] = "Binary";
    SurveyQuestionType["Decimal"] = "Decimal";
    SurveyQuestionType["Explanation"] = "Explanation";
    SurveyQuestionType["Meal"] = "Meal";
    SurveyQuestionType["MultipleChoice"] = "MultipleChoice";
    SurveyQuestionType["Number"] = "Number";
    SurveyQuestionType["OpenText"] = "OpenText";
    SurveyQuestionType["Scale"] = "Scale";
    SurveyQuestionType["SingleChoice"] = "SingleChoice";
    SurveyQuestionType["Snack"] = "Snack";
    SurveyQuestionType["Time"] = "Time";
})(SurveyQuestionType || (exports.SurveyQuestionType = SurveyQuestionType = {}));
var SurveyStatus;
(function (SurveyStatus) {
    SurveyStatus["Done"] = "Done";
    SurveyStatus["Late"] = "Late";
    SurveyStatus["Missing"] = "Missing";
    SurveyStatus["Open"] = "Open";
})(SurveyStatus || (exports.SurveyStatus = SurveyStatus = {}));
var TrackingStatus;
(function (TrackingStatus) {
    TrackingStatus["BeingPrepared"] = "BEING_PREPARED";
    TrackingStatus["Delivered"] = "DELIVERED";
    TrackingStatus["Failure"] = "FAILURE";
    TrackingStatus["Returned"] = "RETURNED";
    TrackingStatus["Transit"] = "TRANSIT";
    TrackingStatus["Unknown"] = "UNKNOWN";
})(TrackingStatus || (exports.TrackingStatus = TrackingStatus = {}));
var TrialStatus;
(function (TrialStatus) {
    TrialStatus["ActiveNotRecruiting"] = "active_not_recruiting";
    TrialStatus["Completed"] = "completed";
    TrialStatus["NotYetRecruiting"] = "not_yet_recruiting";
    TrialStatus["Recruiting"] = "recruiting";
    TrialStatus["Terminated"] = "terminated";
})(TrialStatus || (exports.TrialStatus = TrialStatus = {}));
var UserGender;
(function (UserGender) {
    UserGender["Female"] = "female";
    UserGender["Male"] = "male";
})(UserGender || (exports.UserGender = UserGender = {}));
var UserType;
(function (UserType) {
    UserType["Caretaker"] = "caretaker";
    UserType["ClinicalTrialPatient"] = "clinical_trial_patient";
    UserType["Coach"] = "coach";
    UserType["CustomerService"] = "customer_service";
    UserType["Foodops"] = "foodops";
    UserType["Patient"] = "patient";
    UserType["SiteStaff"] = "site_staff";
})(UserType || (exports.UserType = UserType = {}));
var ValidationType;
(function (ValidationType) {
    ValidationType["Date"] = "date";
    ValidationType["NotAllowed"] = "notAllowed";
})(ValidationType || (exports.ValidationType = ValidationType = {}));
var WeekDay;
(function (WeekDay) {
    WeekDay["Fri"] = "Fri";
    WeekDay["Mon"] = "Mon";
    WeekDay["Sat"] = "Sat";
    WeekDay["Sun"] = "Sun";
    WeekDay["Thu"] = "Thu";
    WeekDay["Tue"] = "Tue";
    WeekDay["Wed"] = "Wed";
})(WeekDay || (exports.WeekDay = WeekDay = {}));
exports.AddMenuDocument = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation addMenu($input: MenuInput!) {\n  addMenu(input: $input) {\n    id\n    name\n    breakfast\n    lunch\n    dinner\n    snacks\n  }\n}\n    "], ["\n    mutation addMenu($input: MenuInput!) {\n  addMenu(input: $input) {\n    id\n    name\n    breakfast\n    lunch\n    dinner\n    snacks\n  }\n}\n    "])));
/**
 * __useAddMenuMutation__
 *
 * To run a mutation, you first call `useAddMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMenuMutation, { data, loading, error }] = useAddMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAddMenuMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.AddMenuDocument, options);
}
exports.DuplicateMenuDocument = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation duplicateMenu($id: ID!) {\n  duplicateMenu(id: $id) {\n    id\n    name\n    breakfast\n    lunch\n    dinner\n    snacks\n  }\n}\n    "], ["\n    mutation duplicateMenu($id: ID!) {\n  duplicateMenu(id: $id) {\n    id\n    name\n    breakfast\n    lunch\n    dinner\n    snacks\n  }\n}\n    "])));
/**
 * __useDuplicateMenuMutation__
 *
 * To run a mutation, you first call `useDuplicateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateMenuMutation, { data, loading, error }] = useDuplicateMenuMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useDuplicateMenuMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.DuplicateMenuDocument, options);
}
exports.UpdateMenuDocument = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateMenu($menuId: String!, $input: MenuUpdateInput!) {\n  updateMenu(menuId: $menuId, input: $input) {\n    id\n    name\n    breakfast\n    lunch\n    dinner\n    snacks\n  }\n}\n    "], ["\n    mutation updateMenu($menuId: String!, $input: MenuUpdateInput!) {\n  updateMenu(menuId: $menuId, input: $input) {\n    id\n    name\n    breakfast\n    lunch\n    dinner\n    snacks\n  }\n}\n    "])));
/**
 * __useUpdateMenuMutation__
 *
 * To run a mutation, you first call `useUpdateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuMutation, { data, loading, error }] = useUpdateMenuMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateMenuMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.UpdateMenuDocument, options);
}
exports.UpdateProfileDocument = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation updateProfile($props: MeInput!) {\n  me(myProps: $props) {\n    id\n    email\n    phone_number\n    name\n    birthdate\n    picture\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n    }\n  }\n}\n    "], ["\n    mutation updateProfile($props: MeInput!) {\n  me(myProps: $props) {\n    id\n    email\n    phone_number\n    name\n    birthdate\n    picture\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n    }\n  }\n}\n    "])));
/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      props: // value for 'props'
 *   },
 * });
 */
function useUpdateProfileMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.UpdateProfileDocument, options);
}
exports.AddDietPlanDocument = (0, client_1.gql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation addDietPlan($input: DietPlanInputV2!) {\n  addDietPlanV2(input: $input) {\n    id\n    name\n    deliveries {\n      id\n      name\n      description\n      sequenceNumber\n      deliveryDayOfWeek\n      quantity\n    }\n    startDate\n    createdAt\n    updatedAt\n  }\n}\n    "], ["\n    mutation addDietPlan($input: DietPlanInputV2!) {\n  addDietPlanV2(input: $input) {\n    id\n    name\n    deliveries {\n      id\n      name\n      description\n      sequenceNumber\n      deliveryDayOfWeek\n      quantity\n    }\n    startDate\n    createdAt\n    updatedAt\n  }\n}\n    "])));
/**
 * __useAddDietPlanMutation__
 *
 * To run a mutation, you first call `useAddDietPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDietPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDietPlanMutation, { data, loading, error }] = useAddDietPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAddDietPlanMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.AddDietPlanDocument, options);
}
exports.DuplicateDietPlanDocument = (0, client_1.gql)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation duplicateDietPlan($dietPlanId: ID!) {\n  duplicateDietPlanV2(dietPlanId: $dietPlanId) {\n    id\n    name\n    deliveries {\n      id\n      name\n      description\n      sequenceNumber\n      deliveryDayOfWeek\n      quantity\n    }\n    startDate\n    createdAt\n    updatedAt\n  }\n}\n    "], ["\n    mutation duplicateDietPlan($dietPlanId: ID!) {\n  duplicateDietPlanV2(dietPlanId: $dietPlanId) {\n    id\n    name\n    deliveries {\n      id\n      name\n      description\n      sequenceNumber\n      deliveryDayOfWeek\n      quantity\n    }\n    startDate\n    createdAt\n    updatedAt\n  }\n}\n    "])));
/**
 * __useDuplicateDietPlanMutation__
 *
 * To run a mutation, you first call `useDuplicateDietPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateDietPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateDietPlanMutation, { data, loading, error }] = useDuplicateDietPlanMutation({
 *   variables: {
 *      dietPlanId: // value for 'dietPlanId'
 *   },
 * });
 */
function useDuplicateDietPlanMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.DuplicateDietPlanDocument, options);
}
exports.UpdateDietPlanDocument = (0, client_1.gql)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    mutation updateDietPlan($dietPlanId: ID!, $input: DietPlanUpdateInputV2!) {\n  updateDietPlanV2(dietPlanId: $dietPlanId, input: $input) {\n    id\n    name\n    deliveries {\n      id\n      name\n      description\n      sequenceNumber\n      deliveryDayOfWeek\n      quantity\n      menu {\n        id\n      }\n    }\n    startDate\n    createdAt\n    updatedAt\n  }\n}\n    "], ["\n    mutation updateDietPlan($dietPlanId: ID!, $input: DietPlanUpdateInputV2!) {\n  updateDietPlanV2(dietPlanId: $dietPlanId, input: $input) {\n    id\n    name\n    deliveries {\n      id\n      name\n      description\n      sequenceNumber\n      deliveryDayOfWeek\n      quantity\n      menu {\n        id\n      }\n    }\n    startDate\n    createdAt\n    updatedAt\n  }\n}\n    "])));
/**
 * __useUpdateDietPlanMutation__
 *
 * To run a mutation, you first call `useUpdateDietPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDietPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDietPlanMutation, { data, loading, error }] = useUpdateDietPlanMutation({
 *   variables: {
 *      dietPlanId: // value for 'dietPlanId'
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateDietPlanMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.UpdateDietPlanDocument, options);
}
exports.CancelDeliveryDocument = (0, client_1.gql)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    mutation cancelDelivery($deliveryId: ID!) {\n  cancelDeliveryV2(deliveryId: $deliveryId)\n}\n    "], ["\n    mutation cancelDelivery($deliveryId: ID!) {\n  cancelDeliveryV2(deliveryId: $deliveryId)\n}\n    "])));
/**
 * __useCancelDeliveryMutation__
 *
 * To run a mutation, you first call `useCancelDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDeliveryMutation, { data, loading, error }] = useCancelDeliveryMutation({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
function useCancelDeliveryMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.CancelDeliveryDocument, options);
}
exports.ReactivateDeliveryDocument = (0, client_1.gql)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    mutation reactivateDelivery($deliveryId: ID!) {\n  reactivateDeliveryV2(deliveryId: $deliveryId)\n}\n    "], ["\n    mutation reactivateDelivery($deliveryId: ID!) {\n  reactivateDeliveryV2(deliveryId: $deliveryId)\n}\n    "])));
/**
 * __useReactivateDeliveryMutation__
 *
 * To run a mutation, you first call `useReactivateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateDeliveryMutation, { data, loading, error }] = useReactivateDeliveryMutation({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
function useReactivateDeliveryMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.ReactivateDeliveryDocument, options);
}
exports.MeDocument = (0, client_1.gql)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    query me {\n  me {\n    id\n    type\n    email\n    phone_number\n    name\n    birthdate\n    picture\n    is_active\n    groups\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n    }\n    created_at\n  }\n}\n    "], ["\n    query me {\n  me {\n    id\n    type\n    email\n    phone_number\n    name\n    birthdate\n    picture\n    is_active\n    groups\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n    }\n    created_at\n  }\n}\n    "])));
/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
function useMeQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.MeDocument, options);
}
function useMeLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.MeDocument, options);
}
exports.ListDishesDocument = (0, client_1.gql)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    query listDishes($first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n  dishes(first: $first, after: $after, filterBy: $filterBy, sortBy: $sortBy) {\n    edges {\n      node {\n        id\n        name\n        photoUrl\n        type\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}\n    "], ["\n    query listDishes($first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n  dishes(first: $first, after: $after, filterBy: $filterBy, sortBy: $sortBy) {\n    edges {\n      node {\n        id\n        name\n        photoUrl\n        type\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}\n    "])));
/**
 * __useListDishesQuery__
 *
 * To run a query within a React component, call `useListDishesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDishesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDishesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
function useListDishesQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.ListDishesDocument, options);
}
function useListDishesLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.ListDishesDocument, options);
}
exports.ListMenusDocument = (0, client_1.gql)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    query listMenus($first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n  menus(first: $first, after: $after, filterBy: $filterBy, sortBy: $sortBy) {\n    edges {\n      node {\n        id\n        name\n        createdAt\n        updatedAt\n        breakfast\n        lunch\n        dinner\n        snacks\n        sides\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}\n    "], ["\n    query listMenus($first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n  menus(first: $first, after: $after, filterBy: $filterBy, sortBy: $sortBy) {\n    edges {\n      node {\n        id\n        name\n        createdAt\n        updatedAt\n        breakfast\n        lunch\n        dinner\n        snacks\n        sides\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}\n    "])));
/**
 * __useListMenusQuery__
 *
 * To run a query within a React component, call `useListMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMenusQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
function useListMenusQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.ListMenusDocument, options);
}
function useListMenusLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.ListMenusDocument, options);
}
exports.ListCtPatientsDocument = (0, client_1.gql)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    query listCtPatients($first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n  ctPatients(first: $first, after: $after, filterBy: $filterBy, sortBy: $sortBy) {\n    edges {\n      node {\n        id\n        name\n        subject_id\n        foodProgram {\n          id\n          dietPlan {\n            id\n            name\n          }\n          status\n          plannedPauses {\n            startDate\n            endDate\n          }\n          userDeliveryMenus {\n            id\n            snacksPerDay\n            sachetsPerDay\n            mealsPerDay\n            weekdaysOnDiet\n          }\n        }\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}\n    "], ["\n    query listCtPatients($first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n  ctPatients(first: $first, after: $after, filterBy: $filterBy, sortBy: $sortBy) {\n    edges {\n      node {\n        id\n        name\n        subject_id\n        foodProgram {\n          id\n          dietPlan {\n            id\n            name\n          }\n          status\n          plannedPauses {\n            startDate\n            endDate\n          }\n          userDeliveryMenus {\n            id\n            snacksPerDay\n            sachetsPerDay\n            mealsPerDay\n            weekdaysOnDiet\n          }\n        }\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}\n    "])));
/**
 * __useListCtPatientsQuery__
 *
 * To run a query within a React component, call `useListCtPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCtPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCtPatientsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
function useListCtPatientsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.ListCtPatientsDocument, options);
}
function useListCtPatientsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.ListCtPatientsDocument, options);
}
exports.GetCtPatientDocument = (0, client_1.gql)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    query getCtPatient($id: String!) {\n  ctPatient(id: $id) {\n    id\n    name\n    subject_id\n    foodProgram {\n      id\n      dietPlan {\n        id\n        name\n      }\n      status\n      plannedPauses {\n        startDate\n        endDate\n      }\n      userDeliveryMenus {\n        id\n        snacksPerDay\n        sachetsPerDay\n        mealsPerDay\n        weekdaysOnDiet\n      }\n    }\n  }\n}\n    "], ["\n    query getCtPatient($id: String!) {\n  ctPatient(id: $id) {\n    id\n    name\n    subject_id\n    foodProgram {\n      id\n      dietPlan {\n        id\n        name\n      }\n      status\n      plannedPauses {\n        startDate\n        endDate\n      }\n      userDeliveryMenus {\n        id\n        snacksPerDay\n        sachetsPerDay\n        mealsPerDay\n        weekdaysOnDiet\n      }\n    }\n  }\n}\n    "])));
/**
 * __useGetCtPatientQuery__
 *
 * To run a query within a React component, call `useGetCtPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCtPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCtPatientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useGetCtPatientQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetCtPatientDocument, options);
}
function useGetCtPatientLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetCtPatientDocument, options);
}
exports.GetPatientUserDocument = (0, client_1.gql)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    query getPatientUser($id: String!) {\n  patientUser(id: $id) {\n    id\n    type\n    email\n    phone_number\n    name\n    picture\n    is_active\n    subject_id\n    associated_site\n    groups\n    is_ct\n    is_learn_active\n    height\n    weight\n    site {\n      id\n      name\n      abbreviation\n    }\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n    }\n    coach {\n      id\n      name\n      email\n      phone_number\n    }\n    backup_coach {\n      id\n      name\n      email\n      phone_number\n    }\n  }\n}\n    "], ["\n    query getPatientUser($id: String!) {\n  patientUser(id: $id) {\n    id\n    type\n    email\n    phone_number\n    name\n    picture\n    is_active\n    subject_id\n    associated_site\n    groups\n    is_ct\n    is_learn_active\n    height\n    weight\n    site {\n      id\n      name\n      abbreviation\n    }\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n    }\n    coach {\n      id\n      name\n      email\n      phone_number\n    }\n    backup_coach {\n      id\n      name\n      email\n      phone_number\n    }\n  }\n}\n    "])));
/**
 * __useGetPatientUserQuery__
 *
 * To run a query within a React component, call `useGetPatientUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useGetPatientUserQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetPatientUserDocument, options);
}
function useGetPatientUserLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetPatientUserDocument, options);
}
exports.ListDietPlansDocument = (0, client_1.gql)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    query listDietPlans($first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n  dietPlansV2(first: $first, after: $after, filterBy: $filterBy, sortBy: $sortBy) {\n    edges {\n      node {\n        id\n        name\n        startDate\n        createdAt\n        updatedAt\n        deliveries {\n          id\n          name\n          description\n          sequenceNumber\n          deliveryDayOfWeek\n          quantity\n          type\n          isInScheduler\n        }\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}\n    "], ["\n    query listDietPlans($first: Int = 10, $after: String, $filterBy: FilterBy, $sortBy: SortBy) {\n  dietPlansV2(first: $first, after: $after, filterBy: $filterBy, sortBy: $sortBy) {\n    edges {\n      node {\n        id\n        name\n        startDate\n        createdAt\n        updatedAt\n        deliveries {\n          id\n          name\n          description\n          sequenceNumber\n          deliveryDayOfWeek\n          quantity\n          type\n          isInScheduler\n        }\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}\n    "])));
/**
 * __useListDietPlansQuery__
 *
 * To run a query within a React component, call `useListDietPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDietPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDietPlansQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
function useListDietPlansQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.ListDietPlansDocument, options);
}
function useListDietPlansLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.ListDietPlansDocument, options);
}
exports.ListDeliveriesDocument = (0, client_1.gql)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    query listDeliveries($patientId: ID!, $date: String!) {\n  deliveriesV2(patientId: $patientId, date: $date) {\n    id\n    foodProgramUser {\n      id\n      patient {\n        id\n        name\n        subject_id\n      }\n      dietPlan {\n        id\n        name\n      }\n      status\n      deliverySettings\n      plannedPauses {\n        startDate\n        endDate\n      }\n    }\n    dietPlanDeliveryMenu {\n      id\n      name\n      description\n      sequenceNumber\n      deliveryDayOfWeek\n      quantity\n      type\n      isInScheduler\n    }\n    deliveryDate\n    openChanges\n    status\n    isCanceled\n    weekOfCycle\n  }\n}\n    "], ["\n    query listDeliveries($patientId: ID!, $date: String!) {\n  deliveriesV2(patientId: $patientId, date: $date) {\n    id\n    foodProgramUser {\n      id\n      patient {\n        id\n        name\n        subject_id\n      }\n      dietPlan {\n        id\n        name\n      }\n      status\n      deliverySettings\n      plannedPauses {\n        startDate\n        endDate\n      }\n    }\n    dietPlanDeliveryMenu {\n      id\n      name\n      description\n      sequenceNumber\n      deliveryDayOfWeek\n      quantity\n      type\n      isInScheduler\n    }\n    deliveryDate\n    openChanges\n    status\n    isCanceled\n    weekOfCycle\n  }\n}\n    "])));
/**
 * __useListDeliveriesQuery__
 *
 * To run a query within a React component, call `useListDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDeliveriesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      date: // value for 'date'
 *   },
 * });
 */
function useListDeliveriesQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.ListDeliveriesDocument, options);
}
function useListDeliveriesLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.ListDeliveriesDocument, options);
}
exports.GetMenuDocument = (0, client_1.gql)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    query getMenu($id: ID!) {\n  menuById(id: $id) {\n    id\n    name\n    breakfast\n    lunch\n    dinner\n    snacks\n    sides\n  }\n}\n    "], ["\n    query getMenu($id: ID!) {\n  menuById(id: $id) {\n    id\n    name\n    breakfast\n    lunch\n    dinner\n    snacks\n    sides\n  }\n}\n    "])));
/**
 * __useGetMenuQuery__
 *
 * To run a query within a React component, call `useGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useGetMenuQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetMenuDocument, options);
}
function useGetMenuLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetMenuDocument, options);
}
exports.GetDietPlanDocument = (0, client_1.gql)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    query getDietPlan($id: ID!) {\n  dietPlanByIdV2(id: $id) {\n    id\n    name\n    deliveries {\n      id\n      name\n      description\n      sequenceNumber\n      deliveryDayOfWeek\n      quantity\n      type\n      isInScheduler\n      menu {\n        id\n        name\n        breakfast\n        lunch\n        dinner\n        snacks\n        sides\n        createdAt\n        updatedAt\n      }\n    }\n    startDate\n    createdAt\n    updatedAt\n  }\n}\n    "], ["\n    query getDietPlan($id: ID!) {\n  dietPlanByIdV2(id: $id) {\n    id\n    name\n    deliveries {\n      id\n      name\n      description\n      sequenceNumber\n      deliveryDayOfWeek\n      quantity\n      type\n      isInScheduler\n      menu {\n        id\n        name\n        breakfast\n        lunch\n        dinner\n        snacks\n        sides\n        createdAt\n        updatedAt\n      }\n    }\n    startDate\n    createdAt\n    updatedAt\n  }\n}\n    "])));
/**
 * __useGetDietPlanQuery__
 *
 * To run a query within a React component, call `useGetDietPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDietPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDietPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useGetDietPlanQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetDietPlanDocument, options);
}
function useGetDietPlanLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetDietPlanDocument, options);
}
exports.GetFeatureFlagsDocument = (0, client_1.gql)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n    query getFeatureFlags {\n  featureFlags {\n    name\n    type\n    enabled\n  }\n}\n    "], ["\n    query getFeatureFlags {\n  featureFlags {\n    name\n    type\n    enabled\n  }\n}\n    "])));
/**
 * __useGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
function useGetFeatureFlagsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetFeatureFlagsDocument, options);
}
function useGetFeatureFlagsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetFeatureFlagsDocument, options);
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
